.page-wrapper {
  .page__services-container {
    flex-direction: row;
    
    &.page-container {
      padding: 20px 16px 40px;
    }
  }
}

.page__services__image {
  width: 41%;
  margin-right: 15px;

  &.green-circle {
    &::before {
      top: -5%;
      right: 0;
    }
  }
}

.page__services__attendance {
  width: 59%;
}

.page__services__list {

  .spares__card-list__item {
    width: 31.5%;
  }

  .spares__card-list__item__category-name {
    margin: 0;
    padding: 5px 0;
  }

  .spares__card-list__item__icon-category {
    img {
      opacity: .1;
    }
    &.noOpacity {
      img {
        opacity: 1;
      }
    }
  }
}

// additionally

.page__additionally {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 60px;

  .page-title {
    margin: 0;
  }

  .button {
    padding: 0 15px;
  }
}

.page__additionally-services {
  width: 100%;

  .spares__card-list__item {
    width: 23%;
  }
}

.title-container {
  padding: 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-title {
    margin: 0;
    padding-bottom: 10px;
  }

  .features {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}

.price-list {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .price-table {
    width: 66%;
  }
  
  .hiddenTable {
    max-height: 210px;
    overflow: hidden;
  }

  table {
    
    th {
      padding: 18.5px 25px;
    }

    td {
      width: 23%;
      
      span {
        color: $blackBackground;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }


  .priceInfo {
    width: 32%;
    max-height: 230px;
  }
}

.black-theme .spares__card-list__item:focus .spares__card-list__item__icon-category img, .spares__card-list__item:hover .spares__card-list__item__icon-category img {
  opacity: .1;
  
  @include hover {
    opacity: .1;
  }
}