.partners__slider {

  .slick-arrow {
    top: -85px;

    &.slick-prev {
      left: 93%;
    }

    &.slick-next {
      right: 0;
    }
  }
}