.promotion-card {
  width: 31.5%;
  margin-bottom: 30px;
  margin-left: 1.5%;
}

.promotion-list {
  justify-content: flex-start;

  img {
    width: 100%;
  }
}