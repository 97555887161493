.services-wrapper {
  background-color: $blackBackground;

  .services-container {
    @include desktopContainer;
    display: flex;
    justify-content: space-between;
    min-height: 515px;
  }

  .service-block__wrapper {
    width: 50%;
  }

  .service-block__container {
    position: relative;
    padding-left: 33px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      top: 16px;
      bottom: -35px;
      left: 1px;
      width: 1px;
      background-color: #3c4048;
    }

    .services-list {
      max-height: 0;
      opacity: 0;
      transition: $transition;
    }

    &.active {
      .services-list {
        max-height: inherit;
        position: static;
        opacity: 1;

        &__item {
          height: auto;
          overflow: visible;
        }
      }

      .services-list__title {
        font-weight: bold;
      }
    }

    .services-list__title {
      color: #fff;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 30px;
      font-family: 'Muller';
      font-weight: 300;
      letter-spacing: .8px;
      transition: $transition;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 11px;
        left: -34px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #09783d;
        z-index: 1;
      }

      @include hover {
        text-decoration: underline;
      }
    }

    .services-list__item {
      color: #ccc;
      transition: $transition;
      text-transform: lowercase;

      .services-list__link {
        color: #ccc;
        font-size: 14px;
        line-height: 1.8;
        transition: $transition;
      }

      @include hover {
        color: #fff;

        .services-list__link {
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }

  .service-block__image {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      display: none;

      &.active {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 572px;
        height: 478px;
      }
    }
  }

  &.truck {
    @include desktopContainer;
    margin: 80px auto 0;
    padding: 0px 16px 110px;
    background-color: transparent;
    position: relative;

    .service-block__wrapper {
      width: 67%;
      margin-top: 21px;
    }

    .service-block__container {
      width: 100%;
      &:not(:first-child) {
        margin-top: 21px;
    }

      &:after {
        display: none;
      }

    }

    .banner__containerWithLines {
      position: absolute;
      top: -4%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      .svgLine {
        display: none;

        &.active {
          display: block;
        }
      }
    }

    .services-list {
      margin: 0;
    }

    .services-list__title {
      color: $blackBackground;
      margin: 0;
    }

    .services-list__item {
      color: $blackBackground;
      transition: $transition;
      height: 0;
      overflow: hidden;

      .services-list__link {
        color: $blackBackground;
        font-size: 14px;
        line-height: 1.8;
        transition: $transition;
      }

      @include hover {
        color: $blackBackground;

        .services-list__link {
          color: $blackBackground;
          font-weight: bold;
        }
      }
    }

    .service-block__image {
      // margin-top: 40px;
      margin-left: 15px;

      &.green-circle {
        &::before {
          left: 75px;
          z-index: -3;
        }
      }

      img {
        width: 506px;
        height: 484px;

        &.active {
          left: 0;
          z-index: -2;
        }
      }
    }
  }
}