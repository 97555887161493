.form__wraper {
  margin: 40px 0;

  &.form__block {
    .form__container {

      .subtitle {
        font-family: "Fira Sans Extra Condensed",sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: .5px;
        text-align: left;
        margin: 0;
      }

      .form {
        margin: 20px 0 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .input {
          width: 25%;
          display: flex;
          flex-direction: column;
        }

        input {
          // max-width: 243px;
          margin: 0;
        }

        .form__checkbox {
          margin: 10px 0 0;
          max-width: 100%;
        }

        input[type="submit"] {
          margin: 0;
          padding: 0 25px;
          width: auto;
        }
      }
    }
  }

}