.spares__wrapper {
  @include desktopContainer;
}

.spares__card-list {
  justify-content: space-between;
  margin-top: 50px;
}

.spares__card-list__item {
  width: 24%;
  margin: 0 0 1.5%;
}

.spares__card-list__item__category-name {
  font-weight: 400;
  
}