// default header
.header-page {
  background-color: $blackBackground;

  // This styles hidden elements for sticky header
  // START
  &__top-bar__links-list {

    .contacts__social,
    .contacts__mail {
      display: none;
    }
  }

  .burger__menu {
    display: none;
  }

  // END

  &__top-bar {

    &__wrapper {
      background-color: #24262d;
    }

    &__container {
      @include desktopContainer;
      padding: 6px 16px;
      display: flex;
      justify-content: space-between;
    }

    &__region {
      float: left;
      color: #fff;
      font-size: 14px;

      p {
        color: #fff;
        margin: 0;
      }

      &__link {
        color: #fff;
        margin-left: 10px;
      }
    }

    &__links-list {
      display: flex;
      justify-content: space-between;
      width: 25.5%;

      &__item {
        font-size: 14px;
        letter-spacing: .2px;
        color: #fff;
        transition: $transition;

        @include hover {
          color: $globalColorLink;
        }
      }
    }
  }

  &__bottom-bar {
    @include desktopContainer;

    &__info-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__logo {
        display: flex;
        width: 35.2465277778%;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #f1f1f1;
        font-family: 'Muller';
        letter-spacing: .12px;

        p {
          color: #f1f1f1;
          letter-spacing: 0.25px;
        }

        img {
          max-width: 195px;
          object-fit: contain;
          margin-right: 22px;
        }


      }

      .button {
        width: 171px;
        border-radius: 2px;
      }

      &__address {
        color: #f1f1f1;
        font-size: 15px;
        line-height: 18px;
        font-weight: 300;
        width: 22.0416666667%;
        margin-left: 22px;
        padding: 16.1px 0;

        address {
          font-style: normal;
        }

        .schema {
          color: #f1f1f1;
        }
      }

      &__contacts {
        margin-left: 22px;
        width: 17.3611111111%;

        .contacts__phone {
          a {
            font-family: 'Muller';
            display: block;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: .08em;
            color: #fff;
          }
        }

        .contacts__mail {
          a {
            font-size: 14px;
            letter-spacing: .5px;
            color: #fff;
            font-family: 'Muller';
          }
        }
      }
    }

    &__nav-list {
      display: flex;
      justify-content: space-between;
      position: relative;
      border-top: 1px solid #3d4048;

      .withSubmenu {
        position: relative;
        overflow: hidden;

        .header-page__bottom-bar__nav-list__submenu {
          position: absolute;
          top: 50px;
          width: 0;
          left: 0;
          background: rgba(40, 43, 53, .99);
          border-radius: 0 0 4px 4px;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          opacity: 0;
          z-index: -3;
          transition: .3s opacity ease-in-out;
          width: 230%;

          .submenu__list {
            list-style: none;
            font-size: 14px;
            line-height: 26px;
            padding: 0 15px;

            &.other {
              padding-top: 10px;
              border-top: 2px solid $globalColorLink;
              width: 90%;
              margin: auto;
              text-align: center;
            }

            &.uppercase {
              text-transform: uppercase;
              font-size: 14px;
              font-family: 'Muller';

              .submenu__item {
                padding: 18px 0;
              }
            }

            .submenu__item {

              &.submenu__title {
                text-transform: uppercase;
                font-family: 'Muller';
              }

              a {
                color: #fff;
                padding: 0 0 5px;
              }
            }
          }
        }

        &:hover {
          overflow: visible;

          .header-page__bottom-bar__nav-list__submenu {
            opacity: 1;
            z-index: 1;
          }
        }
      }

      &__nav-item {
        color: #fff;
        font-size: 14px;
        letter-spacing: .5px;
        font-family: "Muller";
        padding: 18px 10px;
        display: block;
        text-transform: uppercase;

        .arrow-icon {
          position: absolute;
          top: 40%;
          right: 10px;
        }
      }
    }
  }
}

#roadSchema {
  position: fixed;
  top: 10vh;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  opacity: 0;
  width: 100%;
  height: 100vh;
  text-align: center;

  img {
    height: 80%;
  }

  &.active {
    z-index: 11;
    opacity: 1;
  }
}

//sticky header 

.sticky {
  &.header-page {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;

    .burger__menu {
      display: block;
    }

    .contacts__mail {
      display: flex;
      align-items: center;
    }

    .header-page__top-bar,
    .header-page__bottom-bar__nav-list {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: $transition;
    }

    .header-page__bottom-bar__info-block__contacts__social,
    .header-page__bottom-bar__info-block__logo p,
    .header-page__bottom-bar__info-block__contacts .contacts__mail {
      display: none;
    }

    .header-page__bottom-bar__info-block__logo {
      width: 28%;
    }

    .header-page__bottom-bar__info-block__address {
      padding: 5px 0;
    }

    .header-page__top-bar__links-list {
      width: 56%;

      .contacts__social {
        display: flex;
        padding: 0;
        margin-right: 30px;

        .social__link {
          width: 20px;
          height: 20px;
        }
      }

      .contacts__mail {
        a {
          color: #fff;
          font-family: 'Muller';
          font-size: 14px;
        }
      }
    }

    &.showMenu {
      .header-page__top-bar {
        display: flex;
        align-items: center;
        max-height: 300px;
        overflow: visible;
        opacity: 1;

        .header-page__top-bar__container {
          width: 100%;
        }


      }

      .header-page__top-bar__links-list {

        .contacts__social,
        .contacts__mail {
          max-height: 300px;
          overflow: visible;
          opacity: 1;
        }

        .social__link {
          font-size: 10px;
        }
      }

      .header-page__bottom-bar__nav-list {
        max-height: 300px;
        overflow: visible;
        opacity: 1;
      }
    }
  }
}

.showLineFromCenter {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    border-radius: 2px;
    height: 2px;
    width: 0;
    background-color: $globalColorLink;
    transition: $transition;
  }

  @include hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }
}