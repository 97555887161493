.page-wrapper {
  .page-container {
    @include desktopContainer;
  }
}

.page-description {

  &.block {
    @include desktopContainer;
    margin: 50px auto;
  }
}



.service-title-block {
  @include desktopContainer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;

  &__description {
    width: 40%;
  }

  &__image {
    width: 55%;
  }
}

.scrollToTop {
  position: fixed;
  right: 7%;
  bottom: 4%;
  opacity: 0;
  font-size: 14px;
  color: #7d868c;
  text-align: center;
  transition: $transition;

  &.active {
    bottom: 6%;
    opacity: 1;
  }

  p {
    margin: 5px 0 0;
  }

  &__arrow {
    background-color: $globalColorLink;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 12px 9px;
      border-color: transparent transparent #fff transparent;
      color: #fff;
    }
  }
}