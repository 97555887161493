.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 50px;
}
.footer-container {
  @include desktopContainer;

  .contacts-block {
    width: 100%;
    padding: 26.1px 27px 26.1px 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .footer__contacts-wrapper {
      display: flex;
      justify-content: space-between;
      width: 59%;
      margin-left: 11.42857%;
    }
  }
  .contacts__icon {
    max-width: 170px;
    margin: 10px 0 0;
    
    a {
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: .08em;
      font-family: 'Muller';
      transition: $transition;

      @include hover {
        color: $globalColorLink;
      }
    }
  }

  .contacts__address {
    color: #f1f1f1;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    font-weight: 300;
    font-family: "Fira Sans Extra Condensed",sans-serif;

    &::before {
      top: 0;
    }
  }

  .footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 40px 0;

    &__withChilds, &__withoutChilds {
      display: flex;
      justify-content: space-between;
      width: 50%;
    }

    &__withChilds {
      justify-content: space-around;
      width: 40%;
      .footer-menu__list {
        width: 45%;
      }
    }

    &__withoutChilds {
      width: 55%;
      .footer-menu__list {
        width: 35%;
      }
    }

    &__link {
      font-size: 14px;
      line-height: 26px;
      color: #fff;
      letter-spacing: .2px;
      transition: $transition;

      @include hover {
        color: #7d868c;
      }

      &-title {
        text-transform: uppercase;
        color: $globalColorLink;
        font-family: 'Muller';
        font-size: 12px;
        letter-spacing: .45px;
        margin-bottom: 10px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 20%;
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 30px;

    .logoReshka {
      order: 1;
      margin: 0;
    }

    p {
      order: 2;
      width: 74.28571%;
    }
  }
}