.production__wrapper {
  padding: 60px 0;

  .production__container {
    @include desktopContainer;
  }

  .mobile-background {
    display: none;
  }

  .production__description {
    .section__title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
      font-family: 'Muller';
      text-transform: uppercase;
      letter-spacing: .07em;
    }

    .section__text {
      margin-bottom: 24px;
      letter-spacing: .45px;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .production__service-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    min-height: 300px;

    &.tents {
      .production__service-block__description {
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        justify-content: flex-end;

        .production__service-block__title {
          margin-bottom: 0;
        }

        .production__service-block__text {
          margin: 0;
        }

        .button {
          width: 100%;
        }

        &:before {
          background-position: center;
          background-image: url(../img/production-services/service-right-md.jpg)
        }
      }

      .production__service-block__list {
        &:before {
          background-position: right;
          background-image: url(../img/production-services/service-left-sm.jpg)
        }

        @include hover {
          &:before {
            width: 100%;
            background-image: url(../img/production-services/service-right-sm--hover.jpg)
          }

          a {
            color: #fff;
          }
        }
      }
    }

    &.trailer {
      .production__service-block__description {
        &:before {
          background-position: 100% 0;
          background-image: url(../img/production-services/service-left-md.jpg)
        }
      }

      .production__service-block__list {
        &:before {
          background-image: url(../img/production-services/service-right-sm.jpg)
        }

        @include hover {
          &:before {
            background-image: url(../img/production-services/service-left-sm--hover.jpg)
          }
          
          a {
            color: #fff;
          }
        }
      }
    }

    &.reverseOrder {
      .production__service-block__description {
        order: 2;
      }
  
      .production__service-block__list {
        order: 1;
      }
    }

    &__description {
      padding: 25px 50px;
      width: 62.5%;
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        z-index: -2;
        top: 0;
        right: 0;
      }
    }

    &__title {
      color: $globalColorLink;
      text-transform: uppercase;
      font-weight: 300;
      font-family: 'Muller';
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 1.1px;
    }

    &__text {
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      width: 70%;
    }

    &__list {
      width: 36.4%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      border: 1px solid #dee0e2;
      padding: 0;
      margin: 0;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        transition: $transition;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -2;
        top: 0;
        right: 0;
      }

      &-item {
        display: flex;
        align-items: center;
        width: 50%;

        &:nth-child(even) {
          border-left: 1px solid #dee0e2;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-top: 1px solid #dee0e2;
        }

        a {
          color: $blackBackground;
          padding: 15px;
          font-size: 12px;
          line-height: 18px;
          font-family: 'Muller';
          text-transform: uppercase;
          transition: $transition;
        }
      }
    }

    .mobile-button {
      display: none;
    }
  }
}