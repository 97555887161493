.slick-slide {
  .container {
    @include desktopContainer;
    position: static;
    background: transparent;

    .text_container {
      background: rgba(43, 46, 55, .7);
      padding: 30px 20px 30px 67px;
      position: relative;
      width: 52%;

      .slider__title {
        font-size: 54px;
        font-weight: 800;
        margin-top: 0px;
      }
    }
  }
}

.main__slider {
  .slick-arrow {
    background-color: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 50%;
    font-family: 'Font Awesome 5 Free';
    z-index: 9;
    transition: $transition;

    &.slick-prev {
      left: 3%;

      &:before {
        content: '\f104';
      }
    }

    &.slick-next {
      right: 3%;

      &:before {
        content: '\f105';
      }
    }

    &.slick-next, &.slick-prev {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 24px;
        opacity: 1;
        transition: .3s ease-in-out;
      }
      &:hover, &:focus {
        background-color: $globalColorLink;
      }
    }
  }
}