.gydrofication__tech-types {
  padding: 80px 0;

  .page-title.margin {
    margin: 0;
  }

  &__list {

    &-item {
      width: calc(33% - 5px);

    }
  }
}

.gydrofication__suppliers {
  padding: 85px 0;

  &__item {
    &__content {
      display: flex;
      justify-content: space-between;

      &-img {
        width: 20%;
        margin: auto;
        text-align: center;
      }

      &-text {
        width: 80%;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: .7px;
        font-family: 'Muller';
      }
    }

    &__slider-wrapper {
      
    }
  }

  
}