.promotion__wrapper {
  margin: 50px auto;
}

.promotion__container {
  @include desktopContainer;

  .promotion-list {
    display: flex;
    justify-content: space-between;

    &__item {
      width: 45%;
      align-items: flex-start;
      justify-content: space-between;

      &-img {
        width: 30%;
        align-self: center;
      }

      &-description {
        width: 70%;
        padding: 0 30px 0 0;
      }
    }
  }
}


.promotion-list {
  padding: 0 50px;
}

.promotion__title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  color: white;
  text-transform: uppercase;
}