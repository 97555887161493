@import './variables';
@import './mixins';
@import './fonts';
@import './desktop/general';
@import './desktop/header';
@import './desktop/button';
@import './desktop/formPopup';
@import './desktop/popupRegion';
@import './desktop/footer';
@import './desktop/pages/mainPage';
@import './desktop/pages/promotionsList';
@import './desktop/pages/contacts';
@import './desktop/pages/serviceMaintenance';
@import './desktop/pages/carCovers';
@import './desktop/pages/restorationPage';
@import './desktop/pages/sparePage';
@import './desktop/pages/technicsPage';
@import './desktop/pages/gydrofications';

.reconstruction {

  .service-title-block {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 30px;
    }
  }
}

body {
  min-width: 1070px;
  overflow-x: auto;
}