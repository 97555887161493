.model-raw__wrapper {
  background-color: $blackBackground;
  margin-top: 50px;
  margin-bottom: -49px;
  padding: 30px 0;
}

.model-raw__container {
  @include desktopContainer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .page-title {
    margin: 0;
  }

  .button {
    order: 2;
    margin: 0;
    padding: 0 30px;
  }
}

.swiper-container, .swiper-container-row5 {

  .swiper-button {


    &-prev {
      left: 20%;
    }

    &-next {
      right: 20%;
    }
  }

  img {
    height: 278px;
    width: 482px;
  }
}

.swiper-container.row5 {
  // overflow: visible;

  // .swiper-slide {
  //   transform: scale(.5) !important;

  //   &-active {
  //     transform: scale(1) !important;
  //   }
  // }
}