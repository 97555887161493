.contacts-page {
  justify-content: space-between;
  align-items: baseline;
}

.contacts {
  .page-title {
    padding: 40px 0 0;
  }
}

.contacts-form {
  width: 80%;
  margin: 20px;

  &.form__wraper.form__block {
    .form__container {
      width: 97%;
      padding: 20px;
      .form {
        flex-wrap: wrap;
  
        .input {
          width: 48%;
          margin: 0 0 20px;
        }

        input {
          max-width: initial;
          // width: 48%;
          margin-bottom: 4%;
          margin: 0;
        }
  
        textarea {
          max-width: initial;
          margin: 0;
        }

        .form__checkbox {
          order: 1;
          width: 100%;
          margin: 10px 0;
        }

        .button {
          order: 2;
          padding: 0 25px;
        }
      }
    }
  }
}