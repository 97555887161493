.page-spare__category-list {
  justify-content: space-between;

  &__item {
    width: 24%;
  }
}

.page-spare__features {

  &-container {
    @include desktopContainer;
  }

  &-list {
    justify-content: space-around;

    &__item {
      width: 47%;
      align-items: center;

      i {
        margin: 0 0 0 15px;
      }
    }
  }
}

.form-spare {
  &.form-popup__wraper {
    .form__inputs-block {
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      div {
        width: 48%;
        margin-bottom: 20px;
      }
      input {
        width: 100%;
      }
      
      label {
        margin-bottom: 20px;
      }
    }
    .form__checkbox {
      max-width: 100%;
      margin: 0 0 10px;
    }

    .button {
      padding: 0 25px;
    }
  }
}