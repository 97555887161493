.company-branch__wrapper {
  background-color: #f6f7f8;
  padding: 60px 0;
}

.company-branch__container {
  @include desktopContainer;
  display: flex;
  justify-content: space-between;
}

.company-branch__slider {

  .slick-slide {
    height: fit-content;
  }

  .slick-arrow {
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Font Awesome 5 Free';
    z-index: 1;

    &.slick-prev {
      left: 5%;

      &:before {
        content: '\f104';
      }
    }

    &.slick-next {
      right: 5%;

      &:before {
        content: '\f105';
      }
    }

    &.slick-next, &.slick-prev {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 16px;
        opacity: 1;
        transition: .3s ease-in-out;
        z-index: 2;
      }
      &:hover, &:focus {
        background-color: $globalColorLink;
      }
    }
  }
}

.slider-wrapper {
  width: 50%;
  position: relative;
}

.company-branch__slider-count {
  font-style: italic;
  position: absolute;
  color: #fff;
  z-index: 7;
  bottom: 30%;
  left: 45%;
}

.production__description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;

  .section__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    font-family: 'Muller';
    text-transform: uppercase;
    letter-spacing: .07em;
  }

  .section__list {
    padding: 0;
    &-item {
      position: relative;
      list-style: none;
      padding-left: 10px;
      margin-bottom: 10px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 48%;
        width: 3px;
        height: 3px;
        background-color: $globalColorLink;
      }
    }
  }

  .button {
    margin-top: 80px;
  }
}