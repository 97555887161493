.popup-wrapper {
  left: 0;
}

.arrowLink {
  &:before {
    font-size: 7px;
  }
}

.regionPopup {
  flex-direction: row;
  width: 400px;

  .button {
    max-width: 300px;
    width: 300px;
    margin-left: 20px;
    flex-wrap: nowrap;
  }
}