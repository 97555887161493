.form-popup__wraper-outer {
  @include desktopContainer;
  // width: 100%;
  // height: 100%;
  position: relative;

  .form-popup__wraper {
    left: auto;
    top: 20vh;
    height: auto;

    &.success {
      top: 40%;
    }

    .form__container {
      width: 58.2%;

      .form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .subtitle {
        font-size: 20px;
        line-height: 22px;
        font-weight: 300;
        padding: 0 15%;
      }
    }

    .form__inputs-block {
      width: 48%;

      input {
        margin: 0;
      }

      label {
        margin: 0 0 20px;
      }
    }
  }
}